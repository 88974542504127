import React from 'react';

import ShIcon from '../../../shared/components/sh-icon/sh-icon';
import { IconPack } from '../../../shared/models/app-data-model';
import './text-input-container.scss';

export type TextInputProps = {
  id?: string;
  label?: string;
  iconPack?: IconPack[];
  showIcon?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  type?: string;
  errorMessage?: string;
  maxLength?: number;
  formControl: { value: string; valid: boolean };
  onChangeFunction: (event: HTMLInputElement) => void;
  onClickFunction?: (event: any) => void;
  onKeyDownFunction?: (event: any) => void;
  onClearInputFunction?: (event: any) => void;
};

const TextInputContainer: React.FC<TextInputProps> = (props: TextInputProps) => {
  const {
    label,
    id,
    iconPack,
    showIcon,
    isRequired,
    isDisabled,
    type,
    errorMessage,
    maxLength,
    formControl,
    onChangeFunction,
    onClickFunction,
    onKeyDownFunction,
    onClearInputFunction
  } = props;

  return (
    <div
      className={`gradient-wrapper
      ${!formControl.valid ? 'error' : ''}
      ${isDisabled ? 'disabled' : ''}
      `}
      data-testid={isDisabled ? 'disabled' : ''}
    >
      <div className="text-input-container">
        <input
          data-testid={id}
          id={id}
          type="text"
          className={`text
            ${formControl.value !== '' ? 'filled' : ''}
            ${!formControl.valid ? 'error' : ''}
            ${isDisabled ? 'disabled' : ''}`}
          value={formControl.value || ''}
          autoComplete="off"
          onChange={(event) => onChangeFunction(event.target)}
          pattern={type === 'number' ? '[0-9]+' : undefined}
          maxLength={maxLength ? maxLength : undefined}
          onClick={onClickFunction ? (event) => onClickFunction(event) : undefined}
          onKeyDown={onKeyDownFunction ? (event) => onKeyDownFunction(event) : undefined}
        />

        <label
          htmlFor={id}
          className={`label
            ${formControl.value !== '' ? 'filled' : ''}
            ${!formControl.valid ? 'error' : ''}
            ${isDisabled ? 'disabled' : ''}`}
        >
          {label}{' '}
          {isRequired && (
            <span className="required" data-testid={isRequired ? 'required' : ''}>
              *
            </span>
          )}
        </label>
        {showIcon && (
          <button onClick={onClearInputFunction} className="unset-btn unstyled-btn" data-testid="unset-btn">
            <ShIcon iconPack={iconPack} iconName="close" className="icon unset-icon" />
          </button>
        )}
      </div>
      {!formControl.valid && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

TextInputContainer.defaultProps = {
  id: '0',
  label: 'Input Label',
  isRequired: false,
  isDisabled: false,
  type: 'text',
  errorMessage: 'Error',
  maxLength: undefined,
  formControl: { value: '', valid: true }
};

export default TextInputContainer;
